import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { gql, useLazyQuery } from "@apollo/client";
import CoverPlaceholder from "../images/vinyl-record-svgrepo-com.svg";

const thisQuery = gql`
  query GetCategory($categoryId: ID!) {
    category(id: $categoryId) {
      pagetitle
      description
      records(sort: "inner_sort", limit: 999) {
        id
        artistFirst
        artistSecond
        title
        songA
        songB
        label
        comment
        covers {
          url
        }
      }
    }
  }
`;

function Covers({ categoryId }) {
  const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(
    thisQuery,
    {
      variables: {
        categoryId,
      },
    }
  );
  useEffect(() => {
    loadExpenseStatus();
  }, [loadExpenseStatus]);

  if (loading)
    return (
      <div>
        <div className="align-middle mt-16 w-full text-center filter drop-shadow-lg">
          <img
            className="h-32 m-32 animate-spin m-auto"
            src={CoverPlaceholder}
            alt="Loading..."
          />
          <h3 className="text-lg leading-6 font-medium space-y1 mt-8 text-primary-dark animate-pulse font-bold">
            Serial Records loading...
          </h3>
        </div>
      </div>
    );
  if (error) return `Error! ${error.message}`;

  function changeImage(id, covers) {
    if (covers.length >= 2) {
      for (let i = 0; i < covers.length; i++) {
        if (document.getElementById(id).src === covers[i].url) {
          // process.env.GATSBY_STAPI_GRAPHQL +
          if (i + 1 < covers.length) {
            document.getElementById(id).src = covers[i + 1].url; // process.env.GATSBY_STAPI_GRAPHQL +
            return;
          }
          document.getElementById(id).src = covers[0].url; // process.env.GATSBY_STAPI_GRAPHQL +
          return;
        }
      }
    }
  }

  return (
    <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      {data !== undefined && (
        <div className="space-y-12">
          <div className="inline-flex w-full">
            <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <span className="text-3xl text-primary-dark font-extrabold tracking-tight sm:text-4xl">
                {data.category.pagetitle}
              </span>
            </div>
          </div>
          <div id="CatDescription">
            {data.category.description != null && (
              <ReactMarkdown>{data.category.description}</ReactMarkdown>
            )}
          </div>
          <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
            {data.category.records.map((record) => (
              <li key={record.id}>
                <div className="space-y-4 shadow-lg">
                  <div>
                    {record.covers[0] !== undefined && (
                      // process.env.GATSBY_STAPI_GRAPHQL +
                      <img
                        id={record.id}
                        className="cursor-pointer w-full"
                        src={record.covers[0].url}
                        alt=""
                      />
                    )}
                    {record.covers[0] !== undefined &&
                      record.covers.length > 1 && (
                        <div className="w-full -mt-20 mb-16">
                          <nav className="px-4 flex items-center justify-between sm:px-0">
                            <div className="flex-1 flex">
                              {
                                // eslint-disable-next-line
                                  }
                              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                              <div
                                onClick={() =>
                                  changeImage(record.id, record.covers)
                                }
                                className="btn-covers border-t-2 border-transparent p-2 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 bg-white"
                              >
                                <svg
                                  className="mr-3 h-5 w-5 text-gray-400"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span>vorherige Abbildung</span>
                              </div>
                            </div>
                            <div className="flex-1 flex justify-end">
                              {
                                // eslint-disable-next-line
                              }
                              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                              <div
                                onClick={() =>
                                  changeImage(record.id, record.covers)
                                }
                                className="btn-covers border-t-2 border-transparent p-2 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 bg-white"
                              >
                                <span>nächste Abbildung</span>
                                <svg
                                  className="ml-3 h-5 w-5 text-gray-400"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                            </div>
                          </nav>
                        </div>
                      )}
                  </div>
                  <div className="p-6 pb-8 pt-2">
                    <div className="text-lg leading-6 font-medium">
                      {record.artistFirst != null && (
                        <h3 className="text-primary-dark font-bold mb-2">
                          {record.artistFirst}
                          {record.artistSecond != null &&
                            record.artistSecond !== "" &&
                            ` | ${record.artistSecond}`}
                        </h3>
                      )}
                      <table className="text-sm font-light w-full mt-4">
                        <tbody>
                          {record.title != null && record.title !== "" && (
                            <tr className="border-b-2">
                              <td className="pl-1 p-2 text-secondary">
                                Titel:
                              </td>
                              <td>{record.title}</td>
                            </tr>
                          )}
                          {record.songA != null && record.songA !== "" && (
                            <tr className="border-b-2">
                              <td className="pl-1 p-2 text-secondary">A:</td>
                              <td>{record.songA}</td>
                            </tr>
                          )}
                          {record.songB != null && record.songB !== "" && (
                            <tr className="border-b-2">
                              <td className="pl-1 p-2 text-secondary">B:</td>
                              <td>{record.songB}</td>
                            </tr>
                          )}
                          {record.label != null && record.label !== "" && (
                            <tr className="border-b-2">
                              <td className="pl-1 p-2 text-secondary">
                                Label:
                              </td>
                              <td>{record.label}</td>
                            </tr>
                          )}
                          {record.comment != null && record.comment !== "" && (
                            <tr className="border-b-2">
                              <td colSpan="2" className="pl-1 p-2">
                                {record.comment}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Covers;
