import React from "react";

import { createUploadLink } from "apollo-upload-client";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import Covers from "../components/covers";
import Layout from "../components/layout";

function Category() {
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    cache,
    link: createUploadLink({
      uri: process.env.GATSBY_STAPI,
    }),
  });

  let id = 0;

  if (typeof window !== `undefined`) {
    const url = new URL(window.location);
    id = url.searchParams.get("id");
  }

  return (
    <ApolloProvider client={client}>
      <Layout>
        <main className="bg-lightGrey flex-1">
          <Covers categoryId={id} />
        </main>
      </Layout>
    </ApolloProvider>
  );
}

export default Category;
